<script>
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";

export default {
    name: "Validate",

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    data() {
        return {
            cep: null,
            name: null,
            msgCep: "",
            invalid: [],
            msgError: "",
            msgName: "",
            msgNaturalness: "",
            msgFather_name: "",
            msgOwnerTaxpayer: "",
            msgEmail: "",
            msgBirthday: "",
            msgMother_name: "",
            msgPaidAt: "",
            msgLastName: "",
            msgOwnerEmail: "",
            msgOwnerBirthday: "",
            msgTitle: "",
            msgNationality: "",
            msgInstruct: "",
            msgSex: "",
            isBirthdayInvalid: false,
            msgDispatchingAgency: "",
            isNaturalnessInvalid: false,
            isDispatchingAgencyInvalid: false,
            invalid_front_confrontation: false,
            isMotherNameInvalid: false,
            isCreciInvalid: false,
            isInstructInvalid: false,
            isDescriptionInvalid: false,
            msgDescription: "",
            isFatherNameInvalid: false,
            msgDispatchDate: "",
            isFirstNameInvalid: false,
            msgTaxpayer: "",
            msgPassword: "",
            isNameInvalid: false,
            isLastNameInvalid: false,
            isOwnerBirthdayInvalid: false,
            isNameBoletoInvalid: false,
            isMccInvalid: false,
            isBankInvalid: false,
            isAgencyInvalid: false,
            isAgencyDigitInvalid: false,
            isAccountNumberInvalid: false,
            isAccountTypeInvalid: false,
            isAccountNameInvalid: false,
            isNationalityInvalid: false,
            isTitleInvalid: false,
            finalAbaDisable: null,
            secondAbaDisable: null,
            msgFirstName: "",
            isTaxpayerInvalid: false,
            isOwnerTaxpayerInvalid: false,
            msgPhone: "GENERAL.MSG.REQUIRED_FIELD",
        };
    },

    methods: {
        validateTaxpayer(taxpayer) {
            if (!taxpayer) {
                this.isTaxpayerInvalid = true;
                this.invalid.push(this.isTaxpayerInvalid);
                this.msgTaxpayer = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("taxpayer").classList.add("is-invalid");
                document.getElementById("taxpayer").classList.remove('is-valid');
            } else if (taxpayer.length == 14 || taxpayer.length == 18) {
                if (this.validateCNPJ(taxpayer) || this.validateCpf(taxpayer)) {
                    this.isTaxpayerInvalid = false;
                    document.getElementById("taxpayer").classList.add("is-valid");
                } else {
                    this.isTaxpayerInvalid = true;
                    this.invalid.push(this.isTaxpayerInvalid);
                    this.msgTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
                    document.getElementById("taxpayer").classList.add("is-invalid");
                }
            } else {
                this.isTaxpayerInvalid = true;
                this.invalid.push(this.isTaxpayerInvalid);
                this.msgTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
                document.getElementById("taxpayer").classList.add("is-invalid");
            }
        },
        validateCNPJ(cnpj) {
            cnpj = cnpj.replace(/[^\d]+/g, "");

            if (cnpj == "") return false;

            if (cnpj.length != 14) return false;

            if (
                    cnpj == "00000000000000" ||
                    cnpj == "11111111111111" ||
                    cnpj == "22222222222222" ||
                    cnpj == "33333333333333" ||
                    cnpj == "44444444444444" ||
                    cnpj == "55555555555555" ||
                    cnpj == "66666666666666" ||
                    cnpj == "77777777777777" ||
                    cnpj == "88888888888888" ||
                    cnpj == "99999999999999"
            )
                return false;

            let size = cnpj.length - 2;
            let numbers = cnpj.substring(0, size);
            let digits = cnpj.substring(size);
            let sum = 0;
            let pos = size - 7;

            for (var i = size; i >= 1; i--) {
                sum += numbers.charAt(size - i) * pos--;
                if (pos < 2) pos = 9;
            }

            let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (resultado != digits.charAt(0)) return false;

            size = size + 1;
            numbers = cnpj.substring(0, size);
            sum = 0;
            pos = size - 7;

            for (i = size; i >= 1; i--) {
                sum += numbers.charAt(size - i) * pos--;

                if (pos < 2) pos = 9;
            }

            resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);

            if (resultado != digits.charAt(1)) return false;

            return true;
        },
        validateCpf(cpf) {
            return validar(cpf);
        },
    },
};

const validar = (cpf) => checkAll(prepare(cpf));

const notDig = (i) => ![".", "-", " "].includes(i);
const prepare = (cpf) => cpf.trim().split("").filter(notDig).map(Number);
const is11Len = (cpf) => cpf.length === 11;
const notAllEquals = (cpf) => !cpf.every((i) => cpf[0] === i);
const onlyNum = (cpf) => cpf.every((i) => !isNaN(i));

const calcDig = (limit) => (a, i, idx) => a + i * (limit + 1 - idx);
const sumDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0);
const resto11 = (sumDig) => 11 - (sumDig % 11);
const zero1011 = (resto11) => ([10, 11].includes(resto11) ? 0 : resto11);

const getDV = (cpf, limit) => zero1011(resto11(sumDig(cpf, limit)));
const verDig = (pos) => (cpf) => getDV(cpf, pos) === cpf[pos];

const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)];
const checkAll = (cpf) => checks.map((f) => f(cpf)).every((r) => !!r);
</script>
