<template>
    <div>
        <div class="modal-body">
            <div class="row gutters">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                    <div class="form-group">
                        <label for="name" class="required">{{ t('COMPANIES.NAME') }}: </label>
                        <input type="text" id="name" name="name" v-model="data.name"
                               class="form-control"
                               placeholder="Nome">
                        <div v-if="invalid.name" class="validation">
                            {{ invalid.name }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                    <div class="form-group">
                        <label for="taxpayer" class="required">{{ t('COMPANIES.TAXPAYER') }}: </label>
                        <input type="text" id="taxpayer" name="taxpayer" v-model="data.taxpayer"
                               class="form-control" @change="validateTaxpayer(data.taxpayer)"
                               placeholder="000.000.000-00"
                               v-mask="taxpayerMask"
                        >
                        <div v-if="invalid.taxpayer" class="validation">
                            {{ invalid.taxpayer }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                    <div class="form-group">
                        <label for="phone" class="required">{{ t('COMPANIES.PHONE_NOTIFICATION') }}: </label>
                        <input type="text" id="phone" name="phone" v-model="data.responsible_phone"
                               class="form-control"
                               v-mask="responsiblePhoneMask"
                               placeholder="(00) 00000-0000">
                        <div v-if="invalid.responsible_phone" class="validation">
                            {{ invalid.responsible_phone }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                        <label for="documents_on_plan" class="required">{{ t('COMPANIES.DOCUMENTS_ON_PLAN') }}: </label>
                        <input type="number" id="documents_on_plan" name="documents_on_plan"
                               v-model="data.documents_on_plan"
                               class="form-control"
                               placeholder="Documentos no plano">
                        <div v-if="invalid.documents_on_plan" class="validation">
                            {{ invalid.documents_on_plan }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                        <label for="price_plan" class="required">{{ t('COMPANIES.PRICE_PLAN') }} R$: </label>
                        <input type="number" id="price_plan" name="price_plan" v-model="data.price_plan"
                               class="form-control" step="0.01"
                               @change="formatFixed('price_plan')"
                               placeholder="Preço do plano">
                        <div v-if="invalid.price_plan" class="validation">
                            {{ invalid.price_plan }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                        <label for="price_per_additional_document"
                               class="required">{{ t('COMPANIES.PRICE_ADDITIONAL_DOCUMENT') }} R$: </label>
                        <input type="number" id="price_per_additional_document" name="price_per_additional_document"
                               v-model="data.price_per_additional_document"
                               class="form-control" step="0.01"
                               @change="formatFixed('price_per_additional_document')"
                               placeholder="Preço por documento adicional">
                        <div v-if="invalid.price_per_additional_document" class="validation">
                            {{ invalid.price_per_additional_document }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                        <label for="sale_name">{{ t('COMPANIES.SALE_NAME') }}: </label>
                        <input type="text" id="sale_name" name="sale_name" v-model="data.sale_name"
                               class="form-control"
                               placeholder="Nome da venda">
                    </div>
                </div>
                <div class="col-12">
                    <div class="dropdown-divider"></div>
                    <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                        <label class="font-14 required">
                            <i class="icon-domain"/> Tenants:
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row gutters" v-if="!isView">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="tenantname">{{ t('COMPANIES.NAME') }}: </label>
                                <input type="text" id="tenantname" name="tenantname" v-model="tenant.name"
                                       class="form-control"
                                       placeholder="Nome">
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="form-group">
                                <label for="tenant">Id Tenant: </label>
                                <input type="text" id="tenant" name="tenant" v-model="tenant.tenant"
                                       class="form-control"
                                       placeholder="Tenant">
                            </div>
                        </div>
                        <div class="col-1 d-flex">
                            <i style="font-size: 35px; color: #0E5E4E" @click="pushTenant"
                               class="icon-circle-with-plus align-self-center pointer"></i>
                        </div>
                    </div>
                    <div v-if="isView" class="row gutters">
                        <div class="col-6">
                            <p class="font-weight-bold">{{ t('COMPANIES.NAME') }}:</p>
                        </div>
                        <div class="col-5">
                            <p class="font-weight-bold">Id Tenant:</p>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-for="(iten, key) in data.tenants" :key="key">
                    <div class="dropdown-divider"></div>
                    <div class="row gutters">
                        <div class="col-6">
                            <p>{{ iten.name }}</p>
                        </div>
                        <div class="col-5">
                            <p>{{ iten.tenant }}</p>
                        </div>
                        <div class="col-1 d-flex" v-if="!isView">
                            <i style="font-size: 20px; color: #0E5E4E" @click="removeTenant(key)"
                               class=" pl-2 icon-trash align-self-center pointer">
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" @click="close">{{ t('ACTIONS.CLOSE') }}</button>
            <button class="btn btn-primary" v-if="!isView" @click="save">{{ t('ACTIONS.SAVE') }}</button>
        </div>
    </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import {mapActions, mapState} from 'vuex';
import validate from '../../mixins/validate';

export default {
    name: "createModalComponent",
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    mixins: [validate],
    props: {
        isView: {type: Boolean, default: false},
        isEdit: {type: Boolean, default: false},
        isCreate: {type: Boolean, default: false},
    },
    data() {
        return {
            tenant: {},
            invalid: {},
        }
    },
    computed: {
        taxpayerMask() {
            return this.data.taxpayer?.length > 14 ? '##.###.###/####-##' : '###.###.###-##';
        },
        responsiblePhoneMask() {
            return this.data.responsible_phone?.length > 14 ? '(##) #####-####' : '(##) ####-####';
        },
        ...mapState({
            data: (state) => state.company.companyShow,
        })
    },
    methods: {
        ...mapActions({
            createCompany:'createCompany',
            editCompany:'editCompany',
        }),
        formatFixed(action){
            console.log(this.data.price_per_additional_document);
            if (action === 'price_per_additional_document' && this.data.price_per_additional_document){
                this.data.price_per_additional_document = this.data.price_per_additional_document.toFixed(2);
                console.log(this.data.price_per_additional_document);
                return
            }
            if (action === 'price_plan' && this.data.price_plan){
                this.data.price_plan = this.data.price_plan.toFixed(2);
            }
        },
        close() {
            document.getElementById('closeX')?.click();
        },
        save() {
            if (!this.validate()) {
                return;
            }
            if (this.isEdit) {
                let data = JSON.parse(JSON.stringify(this.data))
                if ((this.tenant?.name?.length > 3) && (this.tenant?.tenant?.length > 3)) {
                    if (!data.tenants) data.tenants = [];
                    data.tenants.push({
                        name: this.tenant.name,
                        tenant: this.tenant.tenant,
                    })
                }
                this.$store.commit('changeLoading', true);
                this.editCompany({data});
            } else if (this.isCreate) {
                let data = JSON.parse(JSON.stringify(this.data))
                if ((this.tenant?.name?.length > 3) && (this.tenant?.tenant?.length > 3)) {
                    if (!data.tenants) data.tenants = [];
                    data.tenants.push({
                        name: this.tenant.name,
                        tenant: this.tenant.tenant,
                    })
                }
                this.$store.commit('changeLoading', true);
                this.createCompany({data});
            }
        },
        validate() {
            let valid = true;
            if (!this.data?.name) {
                valid = false;
                this.invalid.name = 'Campo Obrigatório';
            } else this.invalid.name = null;
            if (!this.data?.taxpayer) {
                valid = false;
                this.invalid.taxpayer = 'Campo Obrigatório';
            } else this.invalid.taxpayer = null;
            if (!this.data?.documents_on_plan) {
                valid = false;
                this.invalid.documents_on_plan = 'Campo Obrigatório';
            } else this.invalid.documents_on_plan = null;
            if (!this.data?.price_per_additional_document) {
                valid = false;
                this.invalid.price_per_additional_document = 'Campo Obrigatório';
            } else this.invalid.price_per_additional_document = null;
            if (!this.data?.responsible_phone) {
                valid = false;
                this.invalid.responsible_phone = 'Campo Obrigatório';
            } else this.invalid.responsible_phone = null;
            if (!this.data?.price_plan) {
                valid = false;
                this.invalid.price_plan = 'Campo Obrigatório';
            } else this.invalid.price_plan = null;
            return valid;
        },
        validateTaxpayer(taxpayer) {
            if (taxpayer?.length === 14) {
                if (this.validateCpf(taxpayer)) {
                    this.invalid.taxpayer = false;
                } else {
                    this.invalid.taxpayer = 'CPF inválido';
                }
            } else if (taxpayer?.length === 18) {
                if (this.validateCNPJ(taxpayer)) {
                    this.invalid.taxpayer = false;
                } else {
                    this.invalid.taxpayer = 'CNPJ inválido';
                }
            }
        },
        pushTenant() {
            if (!this.data.tenants) this.data.tenants = [];
            if (!(this.tenant?.name?.length > 3) || !(this.tenant?.tenant?.length > 3)) {
                this.toast.error('Necessário informar um nome e id ao tenant');
                return;
            }
            this.data.tenants.push({
                name: this.tenant.name,
                tenant: this.tenant.tenant,
            })
            this.tenant.name = null;
            this.tenant.tenant = null;
        },
        removeTenant(key) {
            this.data.tenants = this.data.tenants.filter((i, k) => k !== key);
        }
    }
}
</script>

<style scoped>

</style>