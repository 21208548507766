<template>
    <div>
        <load-screen-component/>
        <page-header title="Companias" btn-new="Novo" @newType="setModalCreate" have-search @searching="changePage(1, $event)"></page-header>
        <div class="card" v-if="allCompanies?.data?.length">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <thead>
                        <tr>
                            <th class="text-center" v-for="(title, key) in titles" :key="key">{{ title }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(iten, key) in allCompanies?.data" :key="key">
                            <td class="text-center">{{ iten.name }}</td>
                            <td class="text-center">{{ iten.taxpayer }}</td>
                            <td class="text-center">{{ iten.responsible_phone }}</td>
                            <td class="text-center pt-2 pb-2" style="max-width: 10px">
                                <a type="button" data-toggle="dropdown" aria-haspopup="true"
                                   aria-expanded="false" class="w-50"
                                   style="font-size: 20px" id="listDropdown">
                                    <i class="icon-more-horizontal" title="Opções"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right dropdown-black"
                                     style="min-width: max-content">
                                    <div class="dropdown-item pointer text-white"
                                         data-target="#customModalTwo"
                                         @click="setEditModal(iten.id)"
                                         data-toggle="modal">
                                        <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                                    </div>
                                    <div class="dropdown-item pointer text-white"
                                         data-target="#customModalTwo"
                                         data-toggle="modal" @click="setViewModal(iten.id)">
                                        <i class="icon-eye mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <none-found-component v-if="!allCompanies?.data?.length"
                              msg="Nenhuma Empresa Encontrada"></none-found-component>
        <div class="row gutters">
            <div class="col-2" v-if="!$widthMd(1000)"></div>
            <div :class="!$widthMd(1000) ? 'col-8' : 'col-12'">
                <pagination-component
                        v-if="allCompanies.data && allCompanies?.data?.length"
                        :items="allCompanies"
                        to="/companhias"
                        @changePage="changePage($event, this.search)"></pagination-component>
            </div>
            <div class="col-2" v-if="!$widthMd(1000)">
                <div class="row gutters d-flex justify-content-end"
                     v-if="allCompanies && allCompanies.data && allCompanies.data[0]">
                    <div class="col-8">
                        <label>Por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Items por Página" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
        <modal-component :title-modal="titleModal" modalExtraLarge @close="wichModal= null">
            <template #title>{{ titleModal }}</template>
            <create-modal-component v-if="[1,2,3].includes(wichModal)" :is-edit="wichModal === 2" :is-view="wichModal === 1" :is-create="wichModal === 3"></create-modal-component>
        </modal-component>
    </div>
</template>

<script>
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import pageHeader from '../../components/layouts/pageHeader';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import noneFoundComponent from '../../components/layouts/noneFoundComponent';
import {mapActions, mapState} from 'vuex';
import Select2 from 'vue3-select2-component';
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import modalComponent from '../../components/layouts/modalComponent';
import createModalComponent from './createModalComponent';

export default {
    name: "companyIndex",
    components: {
        loadScreenComponent,
        pageHeader,
        PaginationComponent,
        noneFoundComponent,
        Select2,
        modalComponent,
        createModalComponent,
    },
    mounted() {
        this.$store.commit('changeLoading', true);
        if (!localStorage.getItem('mail')){
            this.$router.push({name:'Home'});
            return;
        }
        let email = localStorage.getItem('mail')
        if (!email.includes('@r2soft.com.br')){
            this.$router.push({name:'Home'});
            return;
        }
        this.indexCompany({})
    },
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    computed: {
        ...mapState({
            allCompanies: (state) => state.company.allCompanies,
        })
    },
    data() {
        return {
            titles: ['Nome', 'CNPJ', 'Telefone', 'Ações'],
            perPage: null,
            search:null,
            titleModal:'',
            paginate: [
                {id: 'selecione', text: 'Selecione'},
                {id: 25, text: 25},
                {id: 50, text: 50},
                {id: 75, text: 75},
                {id: 100, text: 100},
            ],
            wichModal: null,
        }
    },
    methods: {
        ...mapActions({
            indexCompany: 'indexCompany',
            showCompany:'showCompany'
        }),
        changePage(page = 1, search = {}) {
            this.search = search;
            this.$store.commit('changeLoading', true);
            this.indexCompany({page, perpage: this.perPage, search:this.search});
        },
        changePaginate() {
            this.$store.commit('changeLoading', true);
            if (this.perPage === 'selecione') {
                this.perPage = null;
            }
            this.indexCompany({perpage: this.perPage, search:this.search});
        },
        setViewModal(id)
        {
            this.$store.commit('changeLoading', true);
            this.showCompany({id});
            this.titleModal = 'Visualizar';
            this.wichModal = 1;
        },
        setEditModal(id)
        {
            this.$store.commit('changeLoading', true);
            this.showCompany({id});
            this.titleModal = 'Editar';
            this.wichModal = 2;
        },
        setModalCreate()
        {
            this.$store.commit('setShowCompany', {});
            this.titleModal = 'Adicionar';
            this.wichModal = 3;
        },
    }
}
</script>

<style scoped>

</style>